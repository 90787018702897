
import { Component, Vue } from 'vue-property-decorator';
import Office from './office.vue';
import Staff from './staff.vue';

@Component({
  components: { Office, Staff },
  name: 'Business',
})
export default class extends Vue {
  tabKey = 'office';
}
