import { isArray } from '@panstar/common'
import { IOption } from './interface'

/** 级联选项 */
export type CascaderOption = {
  /** 值 */
  value: string
  /** 文本 */
  label: string
  children?: CascaderOption[]
  _origin?: any
}

/** 处理树形数据 把parentId转成parentIds */
export function transTree<T extends { children?: T[]; parentId: string }>(
  ls: T[],
  parentIds = [] as string[],
  key = 'id'
) {
  return ls.map(i => {
    const ids = [...parentIds, (i as any)[key] as string]
    const children = transTree(i.children || [], ids, key) as T[]
    return {
      ...i,
      parentIds: ids.slice(0, -1),
      children: children.length > 0 ? children : null,
    } as T
  })
}

/** 转换为option */
export function toOption<T extends { children?: T[] }>(
  ls: T[],
  fields = { value: 'value', label: 'label' }
): CascaderOption[] {
  return ls.map(i => {
    const children = i.children ? toOption(i.children, fields) : undefined
    // if (children) {
    //   children.unshift({ label: '本部门', value: '' })
    // }
    return {
      _origin: i,
      value: (i as any)[fields.value],
      label: (i as any)[fields.label],
      children,
    }
  })
}

/** 根据value获取选项 */
export function findOption(ls: IOption[], value: string) {
  return ls.find(i => i.value === value) || ({} as IOption)
}
/** 获取数组中的最后一个元素 */
export function getLastItem(item: string | string[]) {
  return isArray(item as any) ? item[item.length - 1] : (item as string)
}

/** 根据职位id获取职位数组 id的格式：AABBCCC */
export function getJobBaseIds(id: string) {
  if (!id) return []
  const first = id.slice(0, 2)
  const second = first + id.slice(2, 4)
  const third = second + id.slice(4, 7)
  if (third !== second) {
    return [first, second, third]
  } else if (second !== first) {
    return [first, second]
  }
  return [first]
}

/** 数组转Map */
export function array2Map<T>(ls: any[], key = 'id') {
  const map = new Map<string, T>()
  ;(ls || []).forEach(i => {
    map.set(i[key], i)
  })
  return map
}
/** 获取Date的月数 */
export function getMouth(date: string) {
  const d = new Date(date)
  const year = d.getFullYear()
  const month = d.getMonth()
  return year * 12 + month
}
