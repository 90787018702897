
import { Component, Vue } from 'vue-property-decorator'
import { statusFilter, statusTypeFilter, phoneFilter } from '@/utils/filters'
import { getOfficeList, getOfficeTree } from '@/api/office'
import { getAccountPage } from '@/api/users'

import InputSearch from '@/components/InputSearch.vue'
import type { IOption, IPagination } from '@/utils/interface'
import type { IOfficeInfo } from '@/api/office'
import type { IUserInfo } from '@/api/users'

@Component({
  components: { InputSearch },
  name: 'Staff',
  filters: {
    statusFilter,
    statusTypeFilter,
    phoneFilter,
  },
})
export default class extends Vue {
  /** 数据 */
  listLoading = false
  listQuery = {
    officeId: '',
    isChildrenInclude: true,
  }

  /** 分页器 */
  pagination: IPagination<IUserInfo> = {
    total: 0,
    pageNum: 1,
    pageSize: 10,
    list: [],
  }

  /** 部门数据 */
  officeList: IOption[] = []
  /** 部门树数据 */
  officeTree: IOfficeInfo[] = []
  treeProps = {
    children: 'children',
    label: 'officeName',
  }

  /** 获取部门数据 */
  async getOfficeList() {
    this.officeList = (await getOfficeList()).map(i => {
      return {
        text: i.officeName,
        value: i.officeId,
      }
    })
  }

  /** 获取部门树数据 */
  async getOfficeTree() {
    this.officeTree = await getOfficeTree()
  }

  async getList() {
    this.listLoading = true
    const { pageNum, pageSize } = this.pagination
    const { recordList, totalCount } = await getAccountPage({
      ...this.listQuery,
      pageNum,
      pageSize,
    })
    this.pagination.list = recordList
    this.pagination.total = totalCount
    this.listLoading = false
  }

  /** 点击树节点 */
  onNodeClick(node: IOfficeInfo) {
    console.log('click node', node)
    this.listQuery.officeId = node.officeId
    this.getList()
  }

  created() {
    this.getList()
    this.getOfficeList()
    this.getOfficeTree()
  }
}
