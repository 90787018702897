
import { Component, Vue } from 'vue-property-decorator'
import { IOption } from '@/utils/interface'
import { toOption } from '@/utils/common'
import { getAccountList } from '@/api/users'
import { getOfficeInfo, getOfficeTree, saveOffice } from '@/api/office'
import XCascader from '@/components/XCascader.vue'

import type { ElForm } from 'element-ui/types/form'
import type { MOfficeInfo } from '@/api/office'
import type { CascaderOption } from '@/utils/common'

@Component({
  components: { XCascader },
  name: 'OfficeEdit',
})
export default class extends Vue {
  /** 弹窗是否可见 */
  dialogVisible = false

  /** 数据 */
  form = {} as MOfficeInfo

  rules = {
    officeName: [{ required: true, message: '请输入部门名称', trigger: 'change' }],
    officeAdmin: [{ required: true, message: '请选择主管人员', trigger: 'change' }],
  }

  /** 选项 */
  options = [] as CascaderOption[]
  /** 人员数据 */
  personList: IOption[] = []
  /** 标题 */
  get title() {
    return `${this.form.officeId ? '编辑' : '新增'}部门`
  }

  async getOptions() {
    const tree = await getOfficeTree()
    const options = toOption(tree, { value: 'officeId', label: 'officeName' })
    options.unshift({ label: '选择为一级部门', value: '' })
    this.options = options
    // console.log('this.options', this.options)
  }

  async getPersonList(officeId = '') {
    this.personList = (await getAccountList({ officeId, isChildrenInclude: true })).map(i => {
      return {
        text: i.name,
        value: i.acctId,
      }
    })
  }

  /** 切换上级部门 */
  onParentChange(values: string[]) {
    const parentId = [...values].pop()

    if (parentId === this.form.officeId) {
      this.$message({ message: '上级部门不能选择本部门', type: 'warning' })
      this.form.parentIds = []
      this.personList = []
    } else {
      this.getPersonList(parentId)
    }
    this.form.officeAdmin = ''
  }

  /** 显示 */
  show(data = {} as MOfficeInfo) {
    this.dialogVisible = true
    this.getOptions()
    this.getPersonList(data.parentId)
    if (data.officeId) {
      getOfficeInfo({ officeId: data.officeId }).then(res => {
        const parentId = data.parentId || ''
        const parentIds = data.parentIds || []
        this.form = {
          ...res,
          parentId,
          parentIds: parentIds.length === 0 ? [''] : parentIds,
        }
      })
    } else {
      this.form = { officeAdmin: '' } as MOfficeInfo
    }
  }

  /** 确认 */
  onOk() {
    ;(this.$refs.form as ElForm).validate(valid => {
      console.log('valid', valid, this.form)
      if (valid) {
        const { parentIds = [], ...other } = this.form
        const parentId = [...parentIds].pop()
        if (parentId) {
          other.parentId = parentId
        }
        saveOffice(other).then(id => {
          this.$message({ message: '保存成功', type: 'success' })
          this.dialogVisible = false
          const ids = [...(parentIds || []), id]
          this.$emit('update', ids)
        })
      } else {
        console.log('error submit!!')
      }
    })
  }

  created() {
    this.getPersonList()
  }
}
